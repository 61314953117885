.modalStopper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: 28px !important;
    width: 682px;
    max-width: 100%;
    height: 360px;
    padding: 30px;
    box-shadow: inset 0 0 0 4px var(--black-purple-opacity);
    text-align: center;
  }
  
  .background-custom-modal-stopper{
    position: absolute;
    bottom: 4px;
    background-color: var(--black-purple);
    width: calc(100% - 8px);
    height: 38%;
    border-radius: 24px;
    z-index: 0;
    margin-left: -26px;
  }
  
  .title-modal-stopper {
    color: var(--dark-purple);
    text-align: center;
    font-family: "Mark Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .arrow-down {
    width: 19px;
  }
  
  .description-modal-stopper {
    color: var(--dark-purple);
    text-align: center;
    font-family: "Mark Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .text-footer-modal-stopper {
    color: var(--white);
    text-align: center;
    font-family: "Mark Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .btn-modal-stopper {
    width: 185px;
    height: 40px;
    border-radius: 200px;
    border: 3px solid var(--ultra-light-purple);
    background: var(--white);
    color: var(--purple);
    text-align: center;
    font-family: "Mark Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .btn-modal-stopper:hover {
    background: var(--ultra-light-purple);
  }
  
  @media (max-width: 575px) {
  
    .modalStopper{
      width: 400px;
      max-width: 100%;
      height: 340px;
    }
  
    .title-modal-stopper {
      font-size: 26px;
    }
  
    .description-modal-stopper {
      font-size: 16px;
    }
  
    .text-footer-modal-stopper {
      font-size: 18px;
    }
  
    .btn-modal-stopper {
      width: 180px;
      height: 40px;
      font-size: 16px;
    }
  
  }