.card-contact-data {
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white);
  padding: 10px;
  width: 90%;
  margin-bottom: 20px;
  min-height: 340px;
}

.card-contact-header {
  border-radius: 8px;
  background-color: var(--black-purple);
  padding: 12px 32px;
}

.contact-header-title {
  color: var(--white, #fff);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.phone-number {
  color: #482481;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 8px;
}

.phone-number-two {
  color: #482481;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 8px;
  text-align: center;
}

.text-countdown {
  color: var(--dark-purple);
  font-family: "Mark Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.send-code-again {
  color: var(--purple);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.security-code-text {
  color: #864bfa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-labels {
  color: #482481;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.code-input {
  height: 40px;
  width: 40px;
  border-radius: 200px;
  border: 2px solid #d7c9ee;
  padding: 10px;
  text-align: center;
  color: var(--Purple, #864bfa);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.code-input::-webkit-outer-spin-button,
.code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para Firefox */
.code-input {
  -moz-appearance: textfield;
}

.code-input:focus {
  border: 2px solid #d7c9ee;
  outline: none;
}

.phone-number-title {
  color: #864bfa;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-icon-cotizacion {
  height: 134px;
}

.icon-text-cotizacion {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 28px;
}

.usd-text {
  color: #864bfa;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 8px;
}

.usd-container {
  border-radius: 100px;
  height: 40px;
  border: 3px solid var(--Purple, #864bfa);
  background-color: #e8e1ef;
  position: absolute;
  left: -18px;
  bottom: 26px;
  width: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.amount-container {
  border-radius: 100px;
  width: 240px;
  border: 3px solid var(--Purple, #864bfa);
  background-color: #7340d8;
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount-text {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.amount-below {
  color: #d7c9ee;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  bottom: -5px;
  right: 50px;
}

.price-discount-container {
  position: relative;
  width: 300px;
  height: 75px;
}

.below-text {
  width: 304px;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container-card-cotizacion {
  border: 4px solid var(--light-purple);
  padding: 20px;
  border-radius: 16px;
  background: var(--ultra-light-grey, #F8F8F8);
}

.container-cotizacion-price {
  padding: 16px 20px 16px 20px;
  border-radius: 20px;
  border: 3px solid var(--light-purple);
}

.text-offer-initial {
  color: var(--dark-purple);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.car-model-cotizacion {
  color: var(--purple);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.car-version-cotizacion {
  color: var(--dark-purple);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.car-year-kms-cotizacion {
  color: var(--dark-purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.price-cotizacion {
  color: var(--purple);
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.footer-card-price {
  color: var(--dark-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.type-money-cotizacion {
  color: var(--light-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.05px;
}

.text-description-cotizacion {
  color: var(--dark-purple);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-footer {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  border-top: 0px;
  position: fixed;
  bottom: 40px;
  margin-left: -155px;
}

.text-footer-cotizacion {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-footer-cotizacion a {
  color: var(--dark-purple);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 950px) {
  .container-cotizacion-price {
    padding: 15px;
  }
  .container-card-cotizacion {
    padding: 15px;
  }
  .card-icon-cotizacion {
    height: 90px;
    margin-top: 0px !important;
  }
  .car-model-cotizacion {
    font-size: 16px;
  }
  .car-version-cotizacion {
    font-size: 14px;
  }
  .car-year-kms-cotizacion {
    font-size: 12px;
  }
  .text-offer-initial {
    font-size: 18px;
  }
  .footer-card-price {
    font-size: 11px;
  }

  .type-money-cotizacion {
    font-size: 10px;
  }
  .price-cotizacion {
    font-size: 20px;
    margin-top: 8px;
  }
  .text-description-cotizacion {
    font-size: 12px;
  }
}

@media screen and (max-width: 460px) {

  .contact-header-title{
    font-size: 14px;
  }

  .phone-number-two{
    font-size: 13px;
  }

  .phone-number-title{
    font-size: 13px;
  }

  .code-input{
    height: 34px;
    width: 34px;
    font-size: 14px;
  }

}