.container-success {
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
}

.title-success {
  color: var(--dark-purple);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.container-price {
  border-radius: 100px;
  border: 3px solid var(--purple);
  background: var(--stroke-purple);
  padding: 5px 30px 5px 30px;
}

.type-money {
  color: var(--light-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.05px;
}

.price {
  color: var(--white);
  font-size: 34px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.text-description-success {
  color: var(--light-purple);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-footer-success {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.container-card-cotizacion {
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background: var(--ultra-light-grey, #F8F8F8);
}

.container-cotizacion-price {
  padding: 16px 20px 16px 20px;
  border-radius: 20px;
  border: 3px solid var(--light-purple);
}

.text-offer-initial {
  color: var(--dark-purple);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.car-model-cotizacion {
  color: var(--dark-purple);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.car-version-cotizacion {
  color: var(--dark-purple);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.car-year-kms-cotizacion {
  color: var(--dark-purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.price-cotizacion {
  color: var(--purple);
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.footer-card-price {
  color: var(--dark-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.type-money-cotizacion {
  color: var(--light-purple);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.05px;
}

.text-description-cotizacion {
  color: var(--dark-purple);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-footer-cotizacion {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-footer-cotizacion a {
  color: var(--dark-purple);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.white-button-inspect {
  width: 230px;
  border: none;
  display: flex;
  height: 50px;
  padding: 13px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  border: 3px solid var(--purple);
  box-shadow: 0px 2px 20px 0px rgba(24, 14, 49, 0.06);
  background-color: transparent;
  color: var(--purple);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.purple-button-inspect {
  display: flex;
  height: 50px;
  padding: 13px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 200px;
  border: 3px solid var(--stroke-purple);
  background: var(--purple);
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.container-btn-success {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 950px) {
  .container-success {
    background-position: bottom center;
    background-size: 100% 60vh;
    background-repeat: no-repeat;
    height: 100%;
  }
  .purple-button-inspect {
    font-size: 14px;
    width: 170px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .white-button-inspect {
    font-size: 14px;
    height: 30px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .container-cotizacion-price {
    padding: 15px;
  }
  .container-card-cotizacion {
    padding: 15px;
  }
  .card-icon-cotizacion {
    height: 90px;
    width: 90px;
    margin-top: 0px !important;
  }
  .car-model-cotizacion {
    font-size: 16px;
  }
  .car-version-cotizacion {
    font-size: 14px;
  }
  .car-year-kms-cotizacion {
    font-size: 12px;
  }
  .text-offer-initial {
    font-size: 18px;
  }
  .footer-card-price {
    font-size: 12px;
  }

  .type-money-cotizacion {
    font-size: 10px;
  }
  .price-cotizacion {
    font-size: 20px;
    margin-top: 8px;
  }
  .text-description-cotizacion {
    font-size: 12px;
  }
}
