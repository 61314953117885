.card-base-auth {
  border-radius: 16px;
  border: 2px solid var(--light-purple);
  background: var(--white);
  padding: 10px;
  width: 90%;
  margin-bottom: 20px;
  min-height: 340px;
}

.header-card-base-auth {
  border-radius: 8px;
  background: var(--black-purple);
  padding: 11px;
}

.text-header-card-base-auth {
  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.division-line {
  display: flex;
  justify-self: center;
  width: 260px;
  height: 1px;
  background-color: var(--ultra-light-purple);
}

.button-google {
  width: 180px;
  height: 34px;
  border-radius: 200px;
  border: 3px solid var(--stroke-purple);
  background: var(--white);
  color: var(--stroke-purple);
  font-family: "Mark Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.google-icon {
  width: 14px;
  align-self: center;
  margin-right: 5px;
}

/* .button-google:hover {
  background-color: #c23c31;
}
 */
.option-login-email {
  color: var(--purple);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.text-not-account {
  color: var(--dark-purple);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-new-account {
  color: var(--purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.btn-auth {
  width: 120px;
  height: 36px;
  border-radius: 200px;
  border: 3px solid var(--stroke-purple);
  background: var(--purple);
  color: var(--white, #fff);
  font-family: "Mark Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.label-or{
  padding: 3px;
  font-size: 12px;
  color: var(--dark-purple);
  font-weight: bold;
}

.security-code-text {
  color: #864bfa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.register-button .purple-button {
  height: 34px;
  font-size: 14px;
}

.verification-phone-button .purple-button {
  width: 180px;
  height: 34px;
  font-size: 14px;
}

@media screen and (max-height: 640px) {

  .register-input .base-input {
    font-size: 14px;
  }

  .card-base-auth {
    border: 2px solid var(--light-purple);
    padding: 8px;
    width: 250px;
    margin-bottom: 40px;
  }
  
  .header-card-base-auth {
    padding: 9px;
  }
  
  .text-header-card-base-auth {
    font-size: 12px;
    font-weight: 700;
  }
  
  .division-line {
    width: 230px;
    height: 1px;
  }
  
  .button-google {
    width: 150px;
    height: 20px;
    border: 2px solid var(--stroke-purple);
    font-family: "Mark Pro";
    font-size: 9px;
    font-weight: 700;
  }
  
  .register-button .purple-button {
    width: 150px !important;
    height: 25px;
    border: 2px solid var(--stroke-purple);
    font-family: "Mark Pro";
    font-size: 11px;
    font-weight: 700;
    padding: 3px;
  }

  .verification-phone-button .purple-button {
    width: 150px !important;
    height: 25px;
    border: 2px solid var(--stroke-purple);
    font-family: "Mark Pro";
    font-size: 11px;
    font-weight: 700;
    padding: 3px;
  }

  .google-icon {
    width: 12px;
    margin-right: 3px;
  }

  .option-login-email {
    font-size: 10px;
    font-weight: 700;
  }
  
  .text-not-account {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .text-new-account {
    font-size: 14px;
    font-weight: 700;
  }
  
  .btn-auth {
    width: 90px;
    height: 30px;
    border: 2px solid var(--stroke-purple);
    font-size: 11px;
  }

  .label-input {
    font-size: 14px !important;
  }

  .security-code-text{
    font-size: 12px !important;
  }
}
