.modal-base-auth {
  background-color: var(--white);
  border-radius: 24px;
  background-image: url("../images/background-modal-auth.png");
  width: 400px !important;
  background-size: 100% 300px;
  background-position: bottom center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2px var(--black-purple-opacity);
  padding: var(--bs-modal-padding);
  min-height: 500px;
}

.icon-modal-auth {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 630px) {
  .modal-base-auth {
    width: 80vw !important;
    background-image: url("../images/background-modal-auth-mobile.png");
    background-size: 100% 300px;
  }
}
