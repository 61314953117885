.custom-modal {
  border-radius: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.lg-modal-background {
  background-image: url("../../images/background-modal-medium.png");
  height: 630px;
}

.xs-modal-background {
  background-image: url("../../images/background-modal-small.png");
  height: 609px;
  /* width: 480px; */
  margin: 0 auto;
}

.fade.modal.show {
  background-color: rgba(24, 14, 49, 0.8);
}

@media (max-width: 450px) {
  .xs-modal-background {
    background-color: white;
    background-image: url("../../images/background-modal-small-curve.png");
    width: 100% !important;
    background-size: contain;
    background-position: bottom center;
    border-radius: 16px !important;
    height: 685px;
    border: 2px solid var(--light-purple);
  }
}

.xl-modal-background {
  background-image: url("../../images/background-modal-big.png");
  height: 698px;
}

.modal-content {
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: transparent;
  width: 100%;
  border: none !important;
  margin: 10px;
}

.icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 450px) {
  .icon-container {
    padding-right: 0px;
  }
}

.icon-close {
  font-size: 20px;
  color: #fff;
}

.button-close {

  background-color: #864bfa;
  border-radius: 50%;
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-close:hover {
  background-color: #7340d8;
}

.medium-modal-size {
  width: 850px !important;
  height: 630px;
}
