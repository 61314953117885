.radio-button-container {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.radio-button-input .form-check-input {
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #864bfa;
}

.radio-button-input .form-check-label {
  font-size: 16px;
}

.radio-button-input :checked {
  border: 2px solid #864bfa;
  background-color: #864bfa;
  color: #180e31;
}

.radio-button-input :focus {
  border: 2px solid #864bfa;
  background-color: #864bfa;
  color: #180e31;
}

.radio-button-label {
  color: #180e31;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radio-button-title {
  color: #482481;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
