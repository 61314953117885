.session-modal {
  border-radius: 24px;
  width: 100%;
  background-color: white;
  padding: 0px;
  height: 100%;
  border: 4px solid var(--Black-Purple, #180e312a);
}

.button-purple-container {
  /* background-image: url("../../images/background-modal-purple.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 56px;
  border-radius: 20px;
  height: 165px;
}

.top-white-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--purple);
  text-align: center;
  font-family: Mark Pro;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text {
  color: var(--purple);
  text-align: center;
  font-family: Mark Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-white-user-nologged {
  border: 3px solid var(--ultra-light-purple);
  border-radius: 200px;
  width: 130px;
  height: 30px;
  color: var(--purple);
  text-align: center;
  font-family: "Mark Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

@media screen and (max-width: 990px) {
  .text {
    font-size: 14px;
  }
  .title {
    font-size: 22px;
  }
  .button-purple-container {
    /* background-image: url("../../images/background-modal-user-nolloged.png"); */
    background-size: 100% 120px;
    background-position: bottom;
    align-items: end;
    justify-content: center;
    padding: 0px;
  }
}
